.toolbar {
    display: inline-block;
    position: fixed;
    height: 100vh;
    z-index: 200;
    right:0;
}

.toolbar-nav{
    display: flex;
    height: 100%;
    align-items: center;
    justify-items: start;
}
.toolbar-logo {
    margin-left: 1rem;
}
.toolbar-logo a {
    color: black;
    text-decoration: none;
    font-size:1.5rem;
}

#toolbar-top {
    position: fixed;
    display: flex;
    align-self: start;
    justify-content: end;
    right: 0;
}

#toggle {
    justify-self: end;
}
#toggle-button {
    padding : 15px;
    align-self: start;
    transition: 2s;
}

.toolbar-nav-items {
    justify-items: start;
    transition: 500ms;
}
.toolbar-nav-items ul {
    list-style: none;
    padding-left: 0;
    padding-right: 15px;
}
.toolbar-nav-items a{
    color: black;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
}

.toolbar-nav-items a:hover,
.toolbar-nav-items a:active {
    font-style: italic;
    text-decoration: underline;
}

li {
    padding-bottom: 2em;
}