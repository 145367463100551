.App {
  text-align: center;
  font-family: 'Asap', sans-serif;
  display: flex;
}

#root {
  width: 100vw;
}

html {
  scroll-behavior: smooth;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.75rem;
  color: black;
}

h1 {
  font-family: 'Lobster';
  font-size: 3rem;
  color: #ffd400;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  color: black;
  z-index: 10;
  font-weight: bolder;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  color: black;
}

h4 {
  font-family: Montserrat;
  font-weight: bold;
  color: black;
}

h5 {
  font-family: Montserrat,sans-serif;
  color: black;
}

h6 {
  font-family: Montserrat,sans-serif;
  color: black;
  font-weight: 500;
}

p {
  font-family: 'Asap', sans-serif;
  color: black;
}
.br {
  margin-bottom: 0.75rem;
}

section {
  padding-top: 5rem;
}

.centering{
  display: grid;
  justify-items: center;
}


/* navigation */
.nav {
  z-index: 300;
  margin: 0;
  position: fixed;
  justify-self: right;
}
.navbar {
  position: fixed;
  width: 100%;
}

.navbar-toggler {
  border: none;
}
.nav-link{
}
.nav-time {
  display: flex;
  justify-content: flex-end;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.subsection {
  display: inline-flex;
  z-index: 5;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
}

.subsection h1 {
  display: inline-table;
}



/* IMPORTANT DO NOT DELETE */
@media (max-width: 767px) {
  .toolbar-nav-items {
    display: none;
  }

}

@media (max-width: 413px) {
  /* to all for sticky */
  #root {
    overflow: hidden;
  }

}

@media (min-width: 768px) {
  .content {
    padding: 5rem;
  }
}
