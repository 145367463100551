#landing {
    display: grid;
    min-height: 100vh;
    min-width: 100vw;
    justify-content: center;
    align-items: end;
    padding: 60px;
    z-index: 1;
}

.landing-tech{
    background-color: #869494;
}
#landing-block {
    text-align: center;
    font-family: 'Slabo 13px', serif;
    width: 80vw;
}


.landing-p {
    display: inline-block;
    max-width: 30rem;
    text-align: center;
}

.landing-p p {
    font-size: 1.15rem;
}
#resume-link:hover {
    font-style: italic;
}


