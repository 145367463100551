#toggle-theme{
    text-align: center;
    display: inline-block;
    bottom:0;
}


.toggle-container {
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
}
#toggle-theme p {
    padding-left: 0.5rem;
    margin-bottom: 0;
    font-size: 0.8rem;
}
.switch {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 1rem;
}

.switch input {display:none;}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 212, 0, 1);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 0.75rem;
    width: 0.75rem;
    left: 0.15rem;
    bottom: 0.15rem;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: black;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(3rem);
    -ms-transform: translateX(3rem);
    transform: translateX(3rem);
}


/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

label {
    margin: 0;
}