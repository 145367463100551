#project-heading{
    display: flex;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.10);
    padding: 2rem;
    width: 100%;
    justify-content: center;
}

#project-heading p {
    margin: 0.5rem 0;
}

#project-heading {
    display: flex;
    align-items: start;
}
.project-description {
    display: inline-block;

}

.project-about {
    display: inline-block;
    margin-right: 3rem;
    margin-bottom: 1rem;
}

@media (min-width: 925px) {
    .project-about {
        display: inline-block;
        margin-right: 3rem;
        max-width: 12rem;
    }

    .project-description {
        max-width: 30rem;
    }
}