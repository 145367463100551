#default-landing-block {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 0 10vw;
    max-width: 100vw;
}

.default-landing-block {
    display: flex;
    justify-content: center;
    align-items: center;
}


.planning h1 {
    font-family: 'Slabo 13px', serif;
    font-weight: 600;
    font-size: 4rem;
    color: black;
}

.planning h2 {
    font-family: 'Slabo 13px', serif;
}

.planning  h3 {
    font-family: 'Montserrat', san-serif;
    font-weight: 500;
}

.planning h5 {
    font-family: 'Asap', sans-serif;
}


.planning h6:hover {
    font-family: 'Slabo 13px', serif;
    transition: 0.5s;
    font-style: italic;
}

#default-landing-block .me {
    height : 23rem;
    margin-left : 1rem;
    display: none;
}

.links {
    display: flex;
    align-items: center;
}

@media (min-width: 834px) {
    #default-landing-block .me {
        display: block;
    }
}