ul {
    list-style: none;
}

#projectNav {
    display: flex;
    padding: 0;
    /*min-height: 100vh;*/
    /*right: 0;*/
    /*padding-right: 1rem;*/
    /*padding-top: 40vh;*/
    align-items: center;
    align-content: center;
    justify-content: center;
}

#projectNav li{
    padding: 2rem 1rem 0 1rem;
    font-size: 1.5rem;
}