.side-drawer{
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    width: 70%;
    max-width: 400px;
    right: 0;
    z-index: 200;
}
.side-drawer .open {
    transform: translateX(0);
}
.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.side-drawer li {
    margin: 0.5rem 0;
}
.side-drawer a {
    text-decoration: none;
    font-size: 1.2rem;
    color: black
}

.side-drawer a:hover, .side-drawer a:active {
    color: gold;
}

@media (min-width: 768px) {
    .side-drawer{
        display: none;
    }
}