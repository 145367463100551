
#about {
    display: grid;
    background-color: #869494;
    position: sticky;
    z-index: 3;
    align-content: center;
    padding-bottom: 3rem;
    min-height: 100vh;
}

.about-container {
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.text-box {
    max-width: 550px;
    padding-right: 2rem;
    padding-left: 2rem;
}

#profile-pic {
    width: 300px;
    height: auto;
    padding-left: 60px;
    text-align: center;
    margin-right: 5rem;
}

#about a{
    color: black;
    text-decoration: underline;
}

#about a:hover{
    font-style: italic;
}
.grid {
    display: grid;
}

#about .grid a{
    text-decoration: none;
}