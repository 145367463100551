.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 27px;
    width: 28px;
    background: transparent;
    border: none;
    padding:0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button-line{
    width: 30px;
    height: 3.5px;
    border-radius: 10px;
    background: black;
}

@media (min-width: 768px) {
    .toggle-button{
        display: none;
    }
}