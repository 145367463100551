#welcome-font{
    margin-bottom: 1rem;
}
path {
    fill: transparent;
}

text {
    fill: #ffd400;
    font-size: 2.5rem;
    font-family: Lobster;
    letter-spacing: 0.05rem;
    border: 0.5px solid #000000;
}