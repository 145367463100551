

#project-component {
    display: grid;
    min-width: 100vw;
    min-height: 100vh;
    background: white;
    z-index: 1;
    align-content: center;
}


.projects .container {
    text-align:  center;
}

.projects {
    display: inline-block;
    text-align: center;
}

#project-component .subsection {
    background-color: white;
}

