#resume-link {
    border-radius: 1rem;
    border: 0.1rem solid black;
    width: 9rem;
    height: 1.75rem;
    text-align: center;
    background: transparent;
    margin: 1rem 1rem;
}
#resume-link a {
    text-decoration: none;
    color: black;
    transition: 0.3s;
    width: 100%;
    height: 100%;
}

#resume-link:hover {
    background: rgba(255, 212, 0, 0.5);

}