
.icon {
    margin-right : 1em;
}

.last-contact {
    margin-right: 0;
}

.contact a {
    text-decoration: none;
}

.contact{
    display: inline-block;
    text-align: center;
    margin-bottom: 1.25rem;
}