


.project-link {
    display: inline-flex;
    margin-bottom: 4rem;
    z-index: 0;
    width: 80vw;
    max-width: 450px;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

}

.project-image {
    display: inline-table;
}
.project-image img {
    width: 80vw;
    max-width: 300px;
    margin-bottom: 0.75rem;
}

.project-link:hover {
    filter:  grayscale(100%);
    transition: 250ms filter;
    text-decoration: none;
    font-style: italic;
}

.project-info {
    display: inline-block;
}

@media (min-width: 768px) {
    .project-link{
        flex-wrap: nowrap;
    }
}